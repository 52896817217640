
#google_translate_element {
	display: inline-block;
	position: relative;
	top: -1px;
	margin-left: 20px;

	.goog-te-gadget-icon {
		border-radius: 2px;
	}

	.goog-te-gadget-simple {
		border: 0;
		width: 100%;
		padding-bottom: 1px;
		background-color: transparent;
		
		&,
		& * {
			font-size: rem(14px) !important;
		}

		[aria-haspopup][href='#'] {
			margin-right: 0;
			display: inline-block;
			text-decoration: none !important;

			span {
				&.gsp1 {
					padding: 0 3px;
					display: inline-block;
					color: inherit;
				}
				
				&.gsp2 {
					padding-right: 3px;
					border-left: 0 !important;
				}
				
				&.gsp3 {
					background: none;
					color: inherit !important;
				}
			}

			img {
				display: none;
			}
		}
	}
}

@include bp("<= 600px") {
	#google_translate_element,
	#goog-gt-tt,
	#goog-gt-tt + div > object,
	iframe.goog-te-menu-frame {
		display: none!important;
	}
}